.prospects-container {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  padding: 5px;
  box-sizing: border-box;
}

.loading {
  font-size: 20px;
}

.updated-container {
  font-size: 12px;
  text-align: center;
}
