.table-container {
  overflow-x: scroll;
  margin-top: 20px;
}

table {
  margin: 0;
  border-spacing: 0;
  font-size: 18px;
}

table td,
table th {
  padding: 5px 20px;
  text-align: left;
}

table th {
  background-color: #053975;
  border: 1px solid #053975;
  color: white;
}
table thead tr:last-child th {
  cursor: pointer;
}

.last_name,
tbody tr td:first-child {
  position: sticky;
  left: -2px;
}

tbody tr td:first-child {
  background-color: white;
  min-width: 180px;
}

.active-col {
  background-color: #a6cffe !important;
  border: 1px solid #a6cffe;
}

.header-active-col {
  background-color: #72b1f9 !important;
  /* border: 1px solid #a6cffe; */
  border: 1px solid #72b1f9;
}

@media only screen and (max-width: 800px) {
  table td,
  table th {
    padding: 5px 8px;
  }
}
