.Footer {
  margin: 50px 0;
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: center;
  box-sizing: border-box;
}

.Footer span:first-child {
  margin-bottom: 10px;
}

.Footer span:last-child {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media only screen and (max-width: 1000px) {
  .footer-links a {
    flex: 0 1 100%;
    margin-top: 5px;
  }

  .Footer {
    width: 100%;
  }
}
