.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: "Dosis", sans-serif;
  padding: 5px;
  box-sizing: border-box;
}

.App h1 {
  box-sizing: border-box;
  text-align: center;
  color: #053975;
}

a,
a:visited,
a:link,
a:hover,
a:after {
  color: black;
  text-decoration: none;
}

.loading {
  margin: 100px 0 40px 0;
  text-align: center;
}
