.games-container {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
  max-width: 100vw;
  padding: 0 5px;
}

.games-container thead tr:nth-child(2) th {
  cursor: auto;
}

.games-tables-container {
  overflow: scroll;
}

table {
  margin-bottom: 30px;
}

@media only screen and (max-width: 800px) {
  .games-container {
    display: inline-block;
  }
}
