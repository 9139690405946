.filter {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 20px 0;
  margin-top: 10px;
}

.filter label {
  padding: 0 10px;
  display: flex;
  justify-content: space-around;
}

.filter label select {
  margin-left: 5px;
  width: 105px;
  font-size: 10px;
}

label {
  flex: 1 1 70%;
  margin: 5px 0;
}

.filter-container {
  box-sizing: border-box;
}

@media only screen and (max-width: 1000px) {
  .filter label {
    flex: 1 1 100%;
    margin-top: 10px;
    justify-content: space-between;
  }

  .filter label select {
    margin-left: 15px;
    width: 150px;
    font-size: 16px;
  }

  .filter-container {
    display: flex;
    justify-content: center;
  }

  .label-container {
    font-size: 16px;
  }

  .filter {
    display: flex;
    flex-direction: column;
  }
}
